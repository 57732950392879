<template>
  <v-text-field
    @blur="setValues"
    @keydown.enter="setValues();$emit('close')"
    class="px-2 py-1"
    autofocus
    clearable
    data-cy="text"
    dense
    hide-details
    maxlength=1000
    v-model="internalValue"
  />
</template>

<script>
import { Condition } from '@/pages/search/controllers/filter-param'

export default {
  data () {
    return {
      internalValue: undefined
    }
  },
  methods: {
    setValues () {
      this.$emit('set-values', (this.internalValue ?? '').split(' ').filter(v => v?.length))
    }
  },
  watch: {
    value: {
      handler (v) {
        this.internalValue = v?.filter(v => v?.length).join(' ')
      },
      immediate: true
    }
  },
  props: {
    cond: Condition,
    value: Array
  }
}
</script>
